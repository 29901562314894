module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"quality":90,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 74.1 74.1\" height=\"18\" width=\"18\">\n                <path d=\"M41.5 55.3c.9 0 1.3 1.1.7 1.7L30.3 68.9c-6.9 6.9-18.2 7-25.1.1v-.1c-6.9-7-6.8-18.2.1-25.1l15.2-15.2c6.9-6.9 18.2-7 25.1-.1.6.6 1.2 1.3 1.7 2 .3.4.2.9-.1 1.3l-3.5 3.5c-1.2 1.2-2.9 1.6-4.2.5-5.2-4.8-9.1-3.8-12.3-.6L12 50.6c-3.2 3.2-3.2 8.4 0 11.6 3.2 3.2 8.4 3.1 11.6 0l8.2-8.2c.3-.3.7-.4 1.1-.2 2.7 1 5.6 1.6 8.6 1.5zM68.9 5.1c-7-6.9-18.2-6.8-25.1.1L31.9 17.1c-.6.6-.2 1.7.7 1.7 3-.1 5.9.5 8.7 1.6.4.1.8.1 1.1-.2l8.2-8.2c3.2-3.2 8.4-3.2 11.6 0 3.2 3.2 3.1 8.4 0 11.6L46.9 38.7c-3.2 3.2-8.4 3.2-11.6.1l-.4-.4c-1.2-1.4-3.3-.8-4.5.3l-3.5 3.5c-.3.3-.4.9-.1 1.3.5.7 1.1 1.4 1.7 2 7 6.9 18.2 6.8 25.1-.1l15.2-15.2c7-6.8 7-18 .1-25.1.1.1 0 .1 0 0z\"/>\n              </svg>\n            ","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-107933755-3","anonymize":true,"head":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Justin Formentin","short_name":"JustinGatsby","description":"Justin Formentin - Writing about code.","start_url":"/","background_color":"#2e3246","theme_color":"#3498DB","display":"fullscreen","icons":[{"src":"/favicon.png","sizes":"192x192","type":"image/png"},{"src":"/favicon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
